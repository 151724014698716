import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NewMainLayout from './components/NewMainLayout';

// Lazy loading components and pages
const NewHome = lazy(() => import("./pages/home/NewHome"));
const NotFound = lazy(() => import("./pages/notFound/NotFound"));

// Import any existing pages/components we still want to use
// (These would need to be wrapped in NewMainLayout)

const NewApp = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <NewMainLayout>
          <Routes>
            <Route index element={<NewHome />} />
            <Route path="/products" element={<div>Products Page</div>} />
            <Route path="/industries" element={<div>Industries Page</div>} />
            <Route path="/contact" element={<div>Contact Page</div>} />
            <Route path="/login" element={<div>Login Page</div>} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </NewMainLayout>
      </Suspense>
    </BrowserRouter>
  );
}

export default NewApp; 